@import './common.scss';
@import './components/m_checkbox/index.scss';
@import './components/protocol_dialog/index.scss';
@import './components/m_city_picker/index.scss';
@import './components/retention_dialog/index.scss';
@import './components/popup_dialog/index.scss';
@import './components/m_city_retreat/index.scss';
@import './components/resell_dialog/index.scss';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.index-page {
  width: 100vw;
  height: 100vh;
  position: relative;

  .countdown-goods {
    margin-bottom: 10px;
  }
  // 倒计时
  .countdown-box {
    // position: absolute;
    // top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    font-size: 20px;
    span {
      margin: 0 5px;
      width: 45px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #fff;
    }
  }
  .order-slider {
    height: rpx(60);
    overflow: hidden;
    color: #fff;
    text-align: center;
  }
  .search-order {
    position: fixed;
    right: 0px;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;
    // background: rgba(#fff, 0.9);
    .serach-img {
      margin: 3px 0;
      // width: 98px;
      height: 34px;
    }
  }

  .kf-box {
    top: 5px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .display-none {
    display: none !important;
  }
  .top-pic {
    width: rpx(750);
    margin-top: rpx(-9);
  }

  .select-card-block {
    padding: rpx(20);
    margin-top: rpx(-9);
    .search-block {
      border-radius: rpx(16);
      border: 1px solid #eee;
      background-color: rgba($color: white, $alpha: 0.1);
      padding: rpx(20);
      .input-block {
        height: rpx(88);
        border-radius: rpx(88);
        line-height: rpx(88);
        background-color: white;

        display: flex;
        align-items: center;
        .input {
          flex: 1;
          height: rpx(80);
          border: none;
          margin: 0 rpx(40);
          &:focus-visible {
            outline: none;
          }
        }
        .search-btn {
          width: rpx(152);
          height: rpx(88);
          border-radius: rpx(88);
          background-color: #f1a90e;
          text-align: center;
          color: white;
        }
      }
      .search-values {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        .search-item {
          width: rpx(146);
          height: rpx(68);
          border-radius: rpx(68);
          line-height: rpx(68);
          color: #f1a90e;
          border: 1px solid #f1a90e;
          background-color: white;
          text-align: center;
          margin-right: rpx(21);
          margin-top: rpx(20);
          &:nth-child(4n) {
            margin-right: 0;
          }
          &.active {
            background-color: #f1a90e;
            color: white;
          }
        }
      }
    }
    .card-pool-block {
      display: flex;
      flex-wrap: wrap;
      margin-top: rpx(20);
      box-sizing: border-box;
      .card-pool-item {
        width: rpx(344);
        height: rpx(150);
        border-radius: rpx(20);
        background-color: white;
        padding: rpx(20);
        box-sizing: border-box;
        margin-right: rpx(16);
        margin-bottom: rpx(14);
        &:nth-child(2n) {
          margin-right: 0;
        }
        .red {
          color: #cc2724;
        }
        .card {
          display: flex;
          font-size: rpx(46);
          font-weight: 600;
          margin-bottom: rpx(10);
        }
        .price-block {
          display: flex;
          justify-content: space-between;
          font-size: rpx(28);
          .price {
            color: #999999;
            text-decoration-line: line-through;
          }
        }
      }
    }
    .next-btn {
      width: rpx(200);
      height: rpx(68);
      border-radius: rpx(68);
      line-height: rpx(68);
      background-color: #f1a90e;
      text-align: center;
      color: white;
      box-sizing: border-box;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: rpx(40);
    }
  }

  .goods-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: rpx(20);
    margin-top: rpx(-9);
    box-sizing: border-box;
    .goods-item {
      width: rpx(344);
      height: rpx(220);
      margin-right: rpx(20);
      margin-bottom: rpx(20);
      border-radius: rpx(30);
      background: linear-gradient(to bottom right, rgb(14, 118, 119), rgb(147, 164, 49));
      color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .checked {
        display: none;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
      &.active {
        background: linear-gradient(to bottom right, rgb(216, 90, 59), rgb(204, 39, 36));

        .checked {
          display: inline-block;
          width: rpx(100);
          height: rpx(100);
          position: absolute;
          right: 0;
          top: 0;
          z-index: 10;
        }
      }
      .text {
        position: relative;
        z-index: 1;
        margin: 0 rpx(20);
        text-align: center;
      }
    }
  }

  .form-block {
    padding: rpx(20);
    margin-top: rpx(-9);
    .select-card-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rpx(20) 0;
      color: #f1a90e;
      .reselect-btn {
        width: rpx(144);
        height: rpx(60);
        border-radius: rpx(60);
        line-height: rpx(60);
        font-size: rpx(24);
        background-color: #f1a90e;
        text-align: center;
        color: white;
        box-sizing: border-box;
      }
    }
    .prop-line {
      display: flex;
      flex-direction: row;
      padding: rpx(20) rpx(10);
      box-sizing: border-box;
      align-items: center;
      background-color: white;
      border-radius: rpx(10);
      font-size: rpx(28);
      margin-bottom: rpx(20);
      .label {
        color: #333;
        width: rpx(160);
        text-align: left;
        line-height: rpx(44);
        .star {
          color: red;
        }
      }
      .prop-input {
        flex: 1;
        font-size: rpx(28);
        height: rpx(48);
        border: none;
        outline: none;
        min-width: 1px;
      }
      .btn-captcha {
        width: rpx(220);
        height: rpx(55);
        line-height: rpx(55);
        text-align: center;
        border-radius: rpx(5);
        background-color: #f1a90e;
        color: white;
        font-size: rpx(30);
        &.disabled {
          background-color: #ddd;
        }
      }
      .city {
        flex: 1;
        &.placeholder {
          color: #757575;
        }
      }
    }
    .protocol-block {
      color: white;
      font-size: rpx(28);
      margin: rpx(40) 0;
      .checkbox {
        margin-right: rpx(14);
        .m-size {
          width: rpx(30);
          height: rpx(30);
          margin-bottom: rpx(-5);
        }
      }
      .protocol {
        color: #f1a90e;
      }
    }
  }
  .btn-confirm {
    height: rpx(96);
    color: white;
    background-color: #f1a90e;
    border-radius: rpx(96);
    line-height: rpx(96);
    text-align: center;
  }
  .zhedie {
    margin-top: -5px;
    color: white;
    height: rpx(200);
    text-align: center;
    padding-top: rpx(20);
  }
  .scroll-back {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    &.d-none {
      display: none;
    }
  }
}
.overflow-hidden {
  overflow: hidden;
}

.retreat-block {
  position: relative;
  margin-bottom: rpx(20);
  display: flex;
  width: 100%;
  height: rpx(60);
  line-height: rpx(60);
  color: #000;
  border: 1px solid #f5f5f5;
  border-radius: rpx(88);
  padding: rpx(10) 0;
  background: #fff;
  span {
    padding-left: rpx(20);
  }
  img {
    width: rpx(40);
  }
  .select-tips {
    position: absolute;
    right: 10px;
    color: #666;
    img {
      width: rpx(30);
      margin-left: rpx(4);
    }
  }
}
// 温馨提示
.tips-error {
  text-align: center;
  color: #cc2724;
  font-weight: bold;
  font-size: rpx(28);
}
