.resell-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 5vh 5vw;
  z-index: 999999;

  .resell-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }

  .resell-center {
    position: relative;
    background-color: #fff;
    border-radius: rpx(20);
    height: 90vh;
    display: flex;
    flex-direction: column;
    z-index: 999;
    padding-bottom: rpx(20);
    padding-left: rpx(10);

    .resell-title-block {
      display: flex;
      flex-direction: row;
      height: rpx(88);
      line-height: rpx(88);
      padding-left: rpx(80);

      .resell-title {
        flex: 1;
        text-align: center;
      }

      .resell-close {
        width: rpx(80);
        height: rpx(80);
        text-align: center;

        .close-icon {
          width: rpx(40);
          height: rpx(40);
        }
      }
    }

    .resell-content {
      overflow: auto;
      flex: 1;

      .resell-content-line {
        padding: rpx(20) rpx(10);
        font-size: rpx(24);
      }
    }
  }
}
