.m_city_retreat {
  position: absolute;
  width: 65%;
  line-height: rpx(60);
  padding-left: rpx(10);
  z-index: 99;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ms-mobile-select {
  position: fixed !important;
}
