.popup-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 5vh 5vw;

  .popup-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }

  .popup-center {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: rpx(40) rpx(40) 0 0;
    height: 80vh;
    display: flex;
    flex-direction: column;
    z-index: 999;

    .popup-title-block {
      display: flex;
      flex-direction: row;
      height: rpx(88);
      line-height: rpx(88);
      padding-left: rpx(80);

      .popup-title {
        flex: 1;
        text-align: center;
      }

      .popup-close {
        width: rpx(80);
        height: rpx(80);
        text-align: center;

        .close-icon {
          width: rpx(40);
          height: rpx(40);
        }
      }
    }

    .popup-content {
      flex: 1;
      overflow-y: auto;

      .popup-content-inner {
        margin: rpx(20);
        margin-top: 0;
        height: 98%;
        overflow: auto;
        font-size: rpx(28);
        background: #fff;
        border-radius: rpx(20) rpx(20) 0 0;
        .popup-inner-top {
          padding: rpx(20) rpx(40);
          border-bottom: 1px solid #ccc;
          span {
            margin-left: rpx(30);
          }
        }
        .popup-inner-content {
          min-height: 76%;
          padding: rpx(20) rpx(40);
          .popup-inner-null {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img {
              width: rpx(400);
              height: rpx(400);
            }
            span {
              margin-top: rpx(30);
              font-weight: bold;
              font-size: rpx(32);
            }
          }
        }
        .popup-inner-bottom {
          padding: rpx(20) rpx(40);
          display: flex;
          align-items: center;
          border-top: 1px solid #ccc;
          .popup-inner-left {
            margin-right: rpx(20);
            width: rpx(50);
            height: rpx(50);
            background: #f0aa3b;
            border-radius: 50%;
            text-align: center;
            line-height: rpx(50);
            color: #fff;
          }
          .popup-inner-right {
            flex: 1;
            span {
              margin-left: rpx(10);
            }
          }
        }
      }
    }
    // 物流轨迹
    .content-item {
      display: flex;
      flex-direction: column;
      position: relative;
      color: #666;
      font-size: rpx(30);
      .content-dot {
        position: absolute;
        width: rpx(10);
        height: 100%;
        .content-item-dot {
          position: absolute;
          left: rpx(-10);
          top: rpx(20);
          width: rpx(20);
          height: rpx(20);
          border-radius: 50%;
          background: #ccc;
        }
        .content-weight-dot {
          top: rpx(30);
          left: rpx(-15);
          width: rpx(30);
          height: rpx(30);
          background: #f0aa3b;
        }
        .content-item-line {
          margin-top: rpx(30);
          height: 100%;
          width: rpx(4);
          background: #ccc;
        }
      }
      .content-timer {
        margin-left: rpx(30);
        margin-bottom: rpx(20);
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        line-height: rpx(50);
        .content-weight-time {
          font-weight: 600;
          color: #f0aa3b;
        }
        .content-weight-context {
          color: #000;
        }
      }
    }
  }
}
